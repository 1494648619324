import moment from 'moment'
import _get from 'lodash/get'

export const data = function() {
  return {
    isDebug: true,
    loading: false,
    privateThread: null,
    privateThreadID: 0,
    privateThreadMessageID: 0,
    privateThreadMessages: [],
    privateThreadParticipants: [],
    privateThreadAttachments: [],
    conversationAttachmentFile: null,
    privateThreadMessageAttachmentID: null,
    author: null,
    subject: '',
    participantsLine: '',
    messageFrom: '',
    referenceType: '',
    referenceId: 0,
    recipientLine: '',
    replyPrivateThreadMessageParent: null,
    toggle: false,
    friendlyMessage: '',
    photoModal: {
      open: false,
      src: ''
    },
    lGet: _get,
    photos: [],
    photoIds: [],
    documents: [],
    documentIds: [],
    styles: {
      mainContainer: {
        minHeight: '1000px'
      }
    },
    vendorHoaID: 0,
    addLabel: '',
    addValue: '',
    filterBy: '',
    vendorHoaList: [],
    recipientList: [],
    recipientsSelected: [],
    toggleCommittee: false,
    toggleVendor: false,
    toggleHomeowner: false,
    toggleBoard: false,
    toggleManagement: false,
    toggleRecipients: false,
    formatDateFn: function(value) {
      return value != null
        ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY hh:mm:ss a')
        : ''
    }
  }
}

export function data() {
  return {
    isDebug: true,
    isOpen: false,
    loading: true,
    replyMessage: '',
    files: [],
    image: null,
    doc: null,
    previews: [],
    docPreviews: []
  }
}

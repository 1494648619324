import { conversationDetail } from '@/services/Conversations/ConversationDetails/store'
import { conversationList } from '@/services/Conversations/store'
import { conversationAttachmentStore } from '@/services/Conversations/ConversationAttachment/store'
import { notifyError } from '@/services/notify'
import _get from 'lodash/get'

export const methods = {
  _get,
  async reload() {
    this.loading = true

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    this.filterBy = ''

    this.privateThreadID = this.$route.params.id || 0

    if (this.isDebug === true)
      console.debug('privateThreadID=' + this.privateThreadID + ', scope=' + this.scope)

    await this.loadMessageAndReplyThreads()

    if (typeof this.authUser.vendorGlobalID === 'number') {
      this.getVendorHoaDropDown()
    }

    await this.resetPersistedArrays()

    loadingComponent.close()

    this.loading = false
  },

  async resetPersistedArrays() {
    await this.$store.dispatch('conversation/saveOtherCommitteeID', {
      committeeID: 0
    })

    await this.$store.dispatch('conversation/saveVendorIDs', {
      vendorIds: []
    })

    await this.$store.dispatch('conversation/saveOwnerIDs', {
      ownerIds: []
    })

    await this.$store.dispatch('conversation/saveBoardCommitteeID', {
      committeeID: 0
    })

    await this.$store.dispatch('conversation/saveAccountManagerIDs', {
      accountManagerIds: []
    })

    this.recipientsSelected = []
  },

  async getVendorHoaDropDown() {
    if (this.isDebug == true) console.debug('in getVendorHoaDropDown()...')
    await conversationList
      .dispatch('getVendorHoaList', {
        vendorGlobalID: this.authUser.vendorGlobalID
      })
      .then(({ list }) => {
        if (list) {
          this.vendorHoaList = list
        }
      })
  },

  openRecipentModal() {
    this.resetPersistedArrays()
    this.toggleRecipients = true
  },

  onRecipientChange() {},

  addRecipientModal(recipient) {
    if (
      (!this.vendorHoaID || this.vendorHoaID <= 0) &&
      this.vendorHoaList &&
      this.vendorHoaList != undefined &&
      this.vendorHoaList.length > 0
    ) {
      notifyError('Please select an hoa before adding a recipient.')
      return
    }

    if (recipient === 'Other Committee') {
      this.toggleRecipients = false
      this.toggleCommittee = true
    } else if (recipient === 'Vendors') {
      this.toggleRecipients = false
      this.toggleVendor = true
    } else if (recipient === 'Homeowners') {
      this.toggleRecipients = false
      this.toggleHomeowner = true
    } else if (recipient === 'Board of Directors') {
      this.toggleRecipients = false
      this.toggleBoard = true
    } else if (recipient === 'Management Portal Users') {
      this.toggleRecipients = false
      this.toggleManagement = true
    } else {
      this.toggleRecipients = false
      this.addRecipient(recipient)
    }
  },

  addRecipient(recipient) {
    console.debug('add recipient=' + recipient)

    let added = false

    if (this.recipientsSelected) {
      const list = this.recipientsSelected
      for (let a = 0; a < list.length; a++) {
        const tempHolder = list[a]

        if (tempHolder.recipient === recipient) {
          added = true
          break
        }
      }
    }

    if (!added) {
      if (recipient === 'Homeowners') {
        if (
          this.selectedOwnerIDsJson &&
          Array.isArray(this.selectedOwnerIDsJson) &&
          this.selectedOwnerIDsJson.length > 0
        ) {
          Array.from(this.selectedOwnerIDsJson).forEach(element => {
            if (element) {
              const newRecipient = `Homeowner - ${element.name}`
              const isThisAlreadyInArray = this.recipientsSelected.filter(
                f => f.recipient === newRecipient
              )

              if (isThisAlreadyInArray && isThisAlreadyInArray.length === 0) {
                this.recipientsSelected.push({
                  recipient: newRecipient,
                  type: 'homeowner',
                  id: element.ownerID
                })
              }
            }
          })
        }
      } else if (recipient === 'Vendors') {
        if (
          this.selectedVendorIDsJson &&
          Array.isArray(this.selectedVendorIDsJson) &&
          this.selectedVendorIDsJson.length > 0
        ) {
          Array.from(this.selectedVendorIDsJson).forEach(element => {
            if (element) {
              const newRecipient = `Vendor - ${element.name}`
              const isThisAlreadyInArray = this.recipientsSelected.filter(
                f => f.recipient === newRecipient
              )

              if (isThisAlreadyInArray && isThisAlreadyInArray.length === 0) {
                this.recipientsSelected.push({
                  recipient: newRecipient,
                  type: 'vendor',
                  id: element.vendorID
                })
              }
            }
          })
        }
      } else if (recipient === 'Management Portal Users') {
        console.log('Management Portal Users=' + JSON.stringify(this.selectedAccountManagerIDsJson))
        if (
          this.selectedAccountManagerIDsJson &&
          Array.isArray(this.selectedAccountManagerIDsJson) &&
          this.selectedAccountManagerIDsJson.length > 0
        ) {
          Array.from(this.selectedAccountManagerIDsJson).forEach(element => {
            if (element) {
              const newRecipient = `Management Portal User - ${element.name}`
              const isThisAlreadyInArray = this.recipientsSelected.filter(
                f => f.recipient === newRecipient
              )

              console.log('addRecipient manager=' + JSON.stringify(element))

              if (isThisAlreadyInArray && isThisAlreadyInArray.length === 0) {
                this.recipientsSelected.push({
                  recipient: newRecipient,
                  type: 'management portal user',
                  id: element.accountManagingUserId
                })
              }
            }
          })
        }
      } else {
        this.recipientsSelected.push({
          recipient
        })
      }
    }

    this.toggleRecipients = true

    console.debug('recipientsSelected=' + JSON.stringify(this.recipientsSelected))
  },

  async loadMessageAndReplyThreads() {
    await conversationDetail
      .dispatch('getMessageWithRepliesById', {
        privateThreadID: this.privateThreadID
      })
      .then(({ result }) => {
        if (result) {
          if (result.friendlyMessage && result.friendlyMessage !== undefined) {
            this.friendlyMessage = 'You were not a participant in this conversation.'
            return
          }

          this.privateThread = result

          if (
            this.privateThread &&
            this.privateThread.referenceDescription &&
            this.privateThread.referenceDescription
          ) {
            this.recipientLine = this.privateThread.referenceDescription
          }
          if (
            this.privateThread &&
            this.privateThread.referenceType &&
            this.privateThread.referenceType
          ) {
            this.referenceType = this.privateThread.referenceType
          }
          if (
            this.privateThread &&
            this.privateThread.referenceId &&
            this.privateThread.referenceId
          ) {
            this.referenceId = this.privateThread.referenceId
          }

          if (result.privateThreadMessages && result.privateThreadMessages != undefined) {
            this.privateThreadMessages = result.ordinalThreadMessages

            if (this.privateThreadMessages) {
              this.privateThreadMessages.map(m => ({
                ...m,
                indentation: 5
              }))

              let previousReplyId = 0
              let rootIndentation = 40

              let counter = 0

              Array.from(this.privateThreadMessages).forEach(async element => {
                if (element) {
                  console.log(
                    'previousReplyId=' +
                      previousReplyId +
                      ', current reply id=' +
                      element.replyToMessageID
                  )
                  counter += 1

                  if (element.indentationLevel) {
                    element.indentation = element.indentationLevel * rootIndentation
                  }

                  if (counter === 1) {
                    element.indentation = 0
                  }

                  if (
                    element.privateThreadMessageAttachments &&
                    element.privateThreadMessageAttachments != undefined
                  ) {
                    this.privateThreadAttachments = element.privateThreadMessageAttachments
                    await this.getAttachments(element.privateThreadMessageID)
                  }
                }
              })

              if (this.isDebug === true)
                console.debug('orderedMessages=' + JSON.stringify(this.orderedMessages))
            }
          }

          if (result.privateThreadParticipants && result.privateThreadParticipants != undefined) {
            this.privateThreadParticipants = result.privateThreadParticipants

            if (
              Array.isArray(this.privateThreadParticipants) &&
              this.privateThreadParticipants.length > 0
            ) {
              let counter = 1
              Array.from(this.privateThreadParticipants).forEach(element => {
                if (
                  element &&
                  element.referenceObjectName &&
                  element.referenceObjectName != undefined
                ) {
                  if (counter === 1) {
                    this.participantsLine = element.referenceObjectName
                  } else {
                    this.participantsLine = `${this.participantsLine}, ${element.referenceObjectName}`
                  }

                  counter += 1
                }
              })
            }

            this.author = this.privateThreadParticipants[0]
            this.subject = this.privateThread.subject || ''

            if (this.author && this.author.aspNetUserID && this.author.identityInformation) {
              this.messageFrom = `${this.author.identityInformation.name} (${
                this.author.participantType
              }) - ${this.formatDateFn(this.author.createdDate)}`
            }
          }
        }
      })
  },

  async replyToMessage(privateThreadMessage) {
    if (this.isDebug === true)
      console.debug('replyToMessage=' + JSON.stringify(privateThreadMessage))

    this.toggle = true
    this.replyPrivateThreadMessageParent = privateThreadMessage
  },

  async getAttachments(parentID) {
    if (this.privateThreadAttachments) {
      Array.from(this.privateThreadAttachments).forEach(async element => {
        if (element && element.privateThreadMessageAttachmentID > 0) {
          if (this.isDebug == true)
            console.debug('privateThreadMessageAttachment=' + JSON.stringify(element))

          try {
            const params = {
              privateThreadMessageAttachmentID: element.privateThreadMessageAttachmentID,
              asBase64: true
            }

            await conversationAttachmentStore
              .dispatch('getConversationGetFile', {
                params: params
              })
              .then(({ returned }) => {
                if (returned) {
                  this.conversationAttachmentFile = returned
                }

                if (this.isDebug == true)
                  console.debug(
                    ' - params' +
                      JSON.stringify(params) +
                      ' - this.conversationAttachmentFile=' +
                      JSON.stringify(this.conversationAttachmentFile)
                  )
                if (
                  this.conversationAttachmentFile &&
                  this.conversationAttachmentFile != undefined
                ) {
                  if (this.isDebug == true)
                    console.debug(
                      'image or pdf............this.searchTerm:' +
                        this.searchTerm +
                        '............' +
                        this.conversationAttachmentFile
                    )

                  if (this.conversationAttachmentFile.indexOf('application/pdf') === -1) {
                    //photos
                    const flattenAddedIds = JSON.stringify(this.photoIds)
                    if (flattenAddedIds.indexOf(element.privateThreadMessageAttachmentID) === -1) {
                      this.photos.push({
                        content: this.conversationAttachmentFile,
                        id: element.privateThreadMessageAttachmentID,
                        privateThreadMessageID: parentID
                      })

                      this.photoIds.push(element.privateThreadMessageAttachmentID)
                    }
                  } else {
                    //documents
                    const flattenDocAddedIds = JSON.stringify(this.documentIds)
                    if (
                      flattenDocAddedIds.indexOf(element.privateThreadMessageAttachmentID) === -1
                    ) {
                      this.documents.push({
                        content: this.conversationAttachmentFile,
                        id: element.privateThreadMessageAttachmentID,
                        privateThreadMessageID: parentID
                      })

                      this.documentIds.push(element.privateThreadMessageAttachmentID)
                    }
                  }

                  if (this.isDebug === true)
                    console.debug('this.photos=' + JSON.stringify(this.photos))
                }
              })
          } catch (e) {
            if (this.isDebug == true) console.debug(e)
          }
        }
      })
    }
  },

  async openPDFModal(id) {
    if (this.documents) {
      for (let a = 0; a < this.documents.length; a++) {
        const document = this.documents[a]
        if (this.isDebug === true)
          console.debug('document id=' + id + ', document id=' + document.id)

        if (document.id === id) {
          this.$refs.pdfModal.open({
            src: this.documents[a].content
          })
          return
        }
      }
    }
  },

  async openPhotoModal(id) {
    if (this.photos) {
      for (let a = 0; a < this.photos.length; a++) {
        const photo = this.photos[a]
        if (this.isDebug === true) console.debug('photo id=' + id + ', photo id=' + photo.id)

        if (photo.id === id) {
          this.photoModal = {
            open: true,
            src: this.photos[a].content
          }
          return
        }
      }
    }
  },

  redirectToReferenceLink() {
    console.debug('redirectToReferenceLink=' + this.referenceType)

    if (this.referenceType && this.referenceType === 'ApInvoice') {
      const path = '/ap-invoice-detail/' + this.referenceId + '#tab'
      this.$router.push({
        path: path,
        params: { id: this.referenceId }
      })
    } else if (this.referenceType && this.referenceType === 'Violation') {
      let path = '/violationtabs/' + this.referenceId + '/violationDetails#tab'

      this.$router.push({
        path: path,
        params: { id: this.referenceId }
      })
    } else if (this.referenceType && this.referenceType === 'Escrow') {
      let path = '/escrowTabs/' + this.referenceId + '/escrowDetails#tab'

      this.$router.push({
        path: path,
        params: { id: this.referenceId }
      })
    } else if (this.referenceType && this.referenceType === 'Suggestion') {
      let path = '/suggestionTabs/' + this.referenceId + '/suggestionDetail#tab'

      this.$router.push({
        path: path,
        params: { id: this.referenceId }
      })
    } else if (this.referenceType && this.referenceType === 'WorkOrder') {
      let path = '/workordertabs/' + this.referenceId + '/workorderDetails#tab'

      this.$router.push({
        path: path,
        params: { id: this.referenceId }
      })
    } else if (this.referenceType && this.referenceType === 'WorkOrderRequest') {
      let path = '/workorderpromotetabs/' + this.referenceId + '/workorderRequestPromote#tab'

      this.$router.push({
        path: path,
        params: { id: this.referenceId }
      })
    } else if (this.referenceType && this.referenceType === 'VendorRequest') {
      let path = '/vendorRequests/' + this.referenceId + '/vendorRequestConversations#tab'

      this.$router.push({
        path: path,
        params: { id: this.referenceId }
      })
    } else if (this.referenceType && this.referenceType === 'ArchitecturalSubmission') {
      const path = '/architecturaltabs/' + this.referenceId + '/architecturalRequestDetails#tab'
      this.$router.push({
        path: path,
        params: { id: this.referenceId }
      })
    } else if (this.referenceType && this.referenceType === 'Amenity') {
      const path = '/amenity/' + this.referenceId
      this.$router.push({
        path: path,
        params: { id: this.referenceId }
      })
    }
  },

  returnToParent() {
    this.$router.push({
      name: 'conversations'
    })
  }
}

<template>
  <div reply-modal>
    <div class="is-size-4">Reply to Message</div>
    <div v-if="parentSubject" class="pl-5 sub-title font-bold">
      {{ `Subject: ${parentSubject}` }}
    </div>
    <div v-if="parentFrom" class="pl-5 sub-title font-bold">
      {{ parentFrom }}
    </div>
    <ValidationObserver ref="formGroup" v-slot="{ handleSubmit }">
      <transition name="fade" mode="out-in" appear>
        <form @submit.prevent="handleSubmit(submitReplyMessage)">
          <fieldset>
            <div class="form-grid">
              <div class="column is-12">
                <div class="column is-12">
                  <label class="label">Message Reply</label>
                  <Markdown :message.sync="replyMessage" />
                </div>
                <div class="column is-12">
                  <section class="pt-6 violation-image-upload">
                    <b-field class="file">
                      <b-upload
                        v-model="files"
                        multiple
                        drag-drop
                        accept="application/pdf, image/png, image/jpeg, image/jpg"
                        class="rectangle"
                      >
                        <section class="force-center float-center">
                          <div class="force-center float-center">
                            <div class="force-center float-center" style="padding-left: 166px">
                              <img
                                class="Fill-1-Copy-11 force-center float-center"
                                src="@/assets/images/fill-1-copy-11.svg"
                                alt="Upload"
                              />
                            </div>
                            <div class="force-center float-center">
                              <div class="Drag-Drop-your-fil">
                                <div class="text-style-1">Drag & Drop</div>
                                <div class="force-center float-center">
                                  Drop your files here or click to upload.
                                </div>
                                <div class="force-center float-center">
                                  Drop a PNG, JPG, JPEG, or PDF file here, or click to
                                </div>
                                <div class="force-center float-center">
                                  Select one or more files from your computer.
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </b-upload>
                    </b-field>

                    <ul id="gallery" class="flex flex-1 flex-wrap -m-1">
                      <li
                        v-show="!previews.length"
                        class="h-full w-full text-center flex flex-col items-center justify-center items-center"
                      >
                        <img
                          class="mx-auto w-32"
                          src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
                          alt="no data"
                        />
                        <span class="text-small text-gray-500">No files selected</span>
                      </li>
                      <li
                        v-for="(image, index) in previews"
                        :key="index"
                        class="p-1 flex w-1/4 h-32"
                      >
                        <div
                          class="align-items-center relative w-full h-full bg-cover"
                          :style="`background-image: url(${image});`"
                        >
                          <button
                            class="delete is-small"
                            type="button"
                            @click="deleteDropFile(index)"
                          />
                          <label
                            class="absolute inset-x-0 bottom-0 bg-black bg-opacity-50 z-10 p-2"
                          >
                            <span style="color:white;">{{ files[index].name }}</span>
                          </label>
                        </div>
                      </li>
                    </ul>
                  </section>
                </div>
                <div class="pt-5">
                  <button type="submit" :disabled="loading" class="button is-primary is-rounded">
                    Add
                  </button>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <ValidationProvider vid="providerErrors" v-slot="{ errors }">
              <div class="field">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </fieldset>
        </form>
      </transition>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import Markdown from '@/components/markdown'

export default {
  props: {
    privateThreadMessageParent: Object,
    parentSubject: String,
    parentFrom: String
  },

  components: {
    ValidationObserver,
    ValidationProvider,
    Markdown
  },

  watch: {
    files() {
      this.updateAttachments()
    }
  },

  mounted() {
    this.reload()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  data,
  methods
}
</script>
<style lang="scss">
.uploader {
  .rectangle {
    width: 244px;
    height: 107px;
    margin: 13px 16px 11px 53px;
    padding: 18px 25px 8px 3px;
    border-radius: 4px;
    border: #979797;
  }
  .Drag-Drop-your-fil {
    width: 192px;
    height: 34px;
    margin: 7px 0 8px 4px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center !important;
    color: #838383;
  }

  .Drag-Drop-your-fil .text-style-1 {
    font-weight: bold;
    color: #6e7776;
  }

  img.Fill-1-Copy-11 {
    width: 24px;
    height: 30px;
    margin: 0 85px 7px 83px;
    object-fit: contain;
  }
}
</style>

/*
  import { conversationDetail } from '@/services/Conversations/ConversationDetails/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'

import { notifyProblem, notifyError } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  async getMessageWithRepliesById({}, { privateThreadID }) {
    try {
      console.debug('in getMessageWithRepliesById...')
      const detail = await kms.get(
        `/Messaging/PrivateThread/GetWithReplyMessages?privateThreadID=${privateThreadID}`
      )

      if (isDebug == true) console.debug('getMessageWithRepliesById=' + JSON.stringify(detail))

      return {
        result: detail
      }
    } catch (exception) {
      if (exception.message && exception.message.indexOf('conversation thread') > -1) {
        const detail = {
          friendlyMessage: exception.message
        }
        return {
          result: detail
        }
      } else {
        notifyProblem(`There was a problem retrieving the the message thread.`)
        console.error(exception)
      }
    }

    return {
      result: {}
    }
  },
  async addMessageReply({ dispatch }, { replyToMessageID, privateThreadID, messageText, done }) {
    if (isDebug == true) console.debug('...in addPrivateThreadWithMessageAndParticipant')
    try {
      const results = await kms.post(`/Messaging/PrivateThreadMessage`, {
        replyToMessageID,
        privateThreadID,
        messageText
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.privateThreadMessageID > 0) {
        console.debug(`Successfully replied to a message.`)
        done({ details: results })
      } else {
        notifyError('There was a problem replying to a message.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem with this message reply.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const conversationDetail = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})

<template>
  <PageContentLayoutOnly role="region" conversation-details>
    <section class="section">
      <div class="level pr-5">
        <div class="pt-5 level-left level-item title">{{ $t('details.title') }}</div>
        <div class="level-right" :style="{ display: 'flex', alignItems: 'center' }">
          <div class="level-item" :style="{ margin: 0 }">
            <svg
              width="30"
              height="30"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              ></path>
            </svg>
          </div>
          <div class="level-item">
            <router-link
              class="button primary"
              :to="{ path: _get($store, 'state.route.from.fullPath', '') }"
              >Back to Conversations</router-link
            >
          </div>
        </div>
      </div>
      <div v-if="friendlyMessage" class="sub-title font-bold">
        {{ friendlyMessage }}
      </div>
      <div v-if="participantsLine" class="sub-title font-bold">
        <span class="pt-6">{{ `Participants: ${participantsLine}` }}</span>
        <span class="pl-3" v-if="!friendlyMessage"
          ><b-button
            style="vertical-align: baseline !important;"
            type="is-primary is-rounded is-small"
            @click.prevent="openRecipentModal"
            >Add Participants</b-button
          ></span
        >
      </div>
      <div v-else-if="!friendlyMessage" class="sub-title font-bold">
        <span class="pt-6">{{ `Participants: ${participantsLine}` }}</span>
        <span class="pl-3"
          ><b-button
            style="vertical-align: baseline !important;"
            type="is-primary is-rounded is-small"
            @click.prevent="openRecipentModal"
            >Add Participants</b-button
          ></span
        >
      </div>
      <div v-if="subject" class="sub-title font-bold pb-2">
        {{ `Subject: ${subject}` }}
      </div>
      <div v-if="recipientLine" class="sub-title font-bold pb-2">
        <a class="href-overflow-wrap" @click.prevent="redirectToReferenceLink()">{{
          `${recipientLine}`
        }}</a>
      </div>
      <div v-if="messageFrom" class="sub-title font-bold pb-2">
        {{ messageFrom }}
      </div>
      <div class="" v-if="privateThreadMessages">
        <div style="padding-bottom:5px;" v-for="(p, index) in privateThreadMessages" :key="index">
          <div
            class="box shadow pl-5"
            :style="
              `border: 1px solid black; margin-left: ${
                p.indentation ? p.indentation | 0 : 0
              }px !important;`
            "
          >
            <div class="font-bold" :style="`color: ${index === 0 ? 'blue' : 'black'}`">
              {{
                `${p.identityInformation.name} (${p.identityInformation.userType}) - ${formatDateFn(
                  p.createdDate
                )}`
              }}
            </div>
            <vue-markdown :source="p.messageText"></vue-markdown>
            <div class="nowrap" v-if="p.privateThreadMessageID">
              <b-button type="is-text" @click.prevent="replyToMessage(p)"
                ><span class="href-overflow-wrap">Reply</span></b-button
              >
            </div>
            <div>
              <span
                v-if="
                  Array.isArray(photos) &&
                    photos.filter(f => f.privateThreadMessageID == p.privateThreadMessageID)
                      .length >= 1
                "
                :style="{
                  position: 'relative',
                  width: '100%',
                  height: '80%',
                  paddingRight: '10px',
                  paddingLeft: '6px',
                  overflowY: 'scroll'
                }"
              >
                <figure
                  v-for="(photo, pindex) in photos.filter(
                    f => f.privateThreadMessageID == p.privateThreadMessageID
                  )"
                  :key="pindex"
                  vio-photo
                  tabindex="0"
                  :aria-label="'photo ' + (pindex + 1)"
                  :style="{
                    position: 'relative',
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',

                    margin: '4px',

                    height: '140px',
                    width: '140px',

                    overflow: 'hidden',
                    borderRadius: '6px',

                    boxShadow: '0 12px 18px 0 rgba(0, 0, 0, 0.25)'
                  }"
                  @click.prevent="openPhotoModal(photo.id)"
                >
                  <transition name="fade">
                    <img
                      :src="photo.content"
                      :style="{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translateX(-50%) translateY(-50%)',

                        flexShrink: '0',
                        maxWidth: '100%',
                        maxHeight: '100%',
                        height: '100%',
                        width: '100%',
                        objectFit: 'contain',

                        cursor: 'pointer'
                      }"
                    />
                  </transition>
                </figure>
              </span>
              <span>
                <figure
                  v-for="(doc, dindex) in documents.filter(
                    f => f.privateThreadMessageID == p.privateThreadMessageID
                  )"
                  :key="dindex"
                  vio-photo
                  tabindex="0"
                  :aria-label="'photo ' + (dindex + 1)"
                  :style="{
                    position: 'relative',
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',

                    margin: '4px',

                    height: '140px',
                    width: '140px',

                    overflow: 'hidden',
                    borderRadius: '6px',

                    boxShadow: '0 12px 18px 0 rgba(0, 0, 0, 0.25)'
                  }"
                  @click.prevent="openPDFModal(doc.id)"
                >
                  <transition name="fade">
                    <PDFTile
                      v-if="documents[dindex]"
                      :src="documents[dindex].content"
                      :style="{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translateX(-50%) translateY(-50%)',
                        flexShrink: '0',
                        maxWidth: '100%',
                        maxHeight: '100%',
                        height: '100%',
                        width: '100%',
                        objectFit: 'contain',

                        cursor: 'pointer'
                      }"
                    />
                  </transition>
                </figure>
              </span>
            </div>
          </div>
        </div>
      </div>
      <PDFModal ref="pdfModal" />
      <b-modal v-model="photoModal.open" :style="{ zIndex: 999 }">
        <p class="image is-4by3">
          <img :src="photoModal.src" />
        </p>
      </b-modal>
      <Modal :toggle.sync="toggle">
        <ReplyModal
          :toggle="toggle"
          :privateThreadMessageParent="this.replyPrivateThreadMessageParent"
          :parentSubject="subject"
          :parentFrom="messageFrom"
        >
        </ReplyModal>
      </Modal>
      <Modal :toggle.sync="toggleCommittee">
        <CommitteeModal :toggle="toggleCommittee" :VendorHoaID="vendorHoaID"> </CommitteeModal>
      </Modal>
      <Modal :toggle.sync="toggleVendor">
        <VendorModal :toggle="toggleVendor" :VendorHoaID="vendorHoaID"> </VendorModal>
      </Modal>
      <Modal :toggle.sync="toggleHomeowner">
        <HomeownerModal :toggle="toggleHomeowner" :VendorHoaID="vendorHoaID"> </HomeownerModal>
      </Modal>
      <Modal :toggle.sync="toggleBoard">
        <BoardModal :toggle="toggleBoard" :VendorHoaID="vendorHoaID"> </BoardModal>
      </Modal>
      <Modal :toggle.sync="toggleManagement">
        <ManagementModal :toggle="toggleManagement" :VendorHoaID="vendorHoaID"> </ManagementModal>
      </Modal>
      <Modal :toggle.sync="toggleRecipients">
        <RecipientsModal
          :toggle="toggleRecipients"
          :recipientList="recipientList"
          :addLabel="addLabel"
          :addValue="parseInt(addValue)"
          :filterByString="filterBy"
          :onChange="onRecipientChange"
          :addRecipientModal="addRecipientModal"
          :recipientsSelected.sync="recipientsSelected"
          :privateThreadID="parseInt(privateThreadID)"
        >
        </RecipientsModal>
      </Modal>
    </section>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ReplyModal from './components/ReplyModal'
import PDFModal from '@/components/Modals/PDF'
import PDFTile from '@/components/tiles/PDF'
import Modal from '@/components/Modal'
import CommitteeModal from '@/pages/Shared/communications/Global/components/MessageModal/components/committees'
import VendorModal from '@/pages/Shared/communications/Global/components/MessageModal/components/vendors'
import HomeownerModal from '@/pages/Shared/communications/Global/components/MessageModal/components/homeowners'
import BoardModal from '@/pages/Shared/communications/Global/components/MessageModal/components/boardOfDirectors'
import ManagementModal from '@/pages/Shared/communications/Global/components/MessageModal/components/accountManagers'
import RecipientsModal from '@/pages/Shared/communications/Global/components/MessageModal/components/recipients'
import { data } from './keys/data'
import { methods } from './keys/methods'
import VueMarkdown from 'vue-markdown'

export default {
  components: {
    PageContentLayoutOnly,
    ReplyModal,
    Modal,
    PDFModal,
    PDFTile,
    VueMarkdown,
    CommitteeModal,
    VendorModal,
    HomeownerModal,
    BoardModal,
    ManagementModal,
    RecipientsModal
  },

  data,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('conversation', [
        'selectedVendorIDs',
        'selectedVendorIDsJson',
        'selectedBoardCommitteeID',
        'selectedOtherCommitteeID',
        'selectedOwnerIDs',
        'selectedOwnerIDsJson',
        'selectedAccountManagerIDS',
        'selectedAccountManagerIDsJson'
      ])
    }),
    ...mapGetters('user', ['authUser'])
  },

  watch: {
    hoaId() {
      this.reload()
    },
    toggle() {
      if (this.toggle == false) {
        setTimeout(() => {
          this.reload()
        }, 2000)

        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    },
    toggleCommittee() {
      if (this.toggleCommittee == false) {
        console.debug('watched toggle other committee selected=' + this.selectedOtherCommitteeID)
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggleCommittee = false))
        }

        if (
          this.selectedOtherCommitteeID &&
          this.selectedOtherCommitteeID != undefined &&
          this.selectedOtherCommitteeID > 0
        ) {
          this.addRecipient('Other Committee')
        } else {
          console.debug('else selectedOtherCommitteeID=' + this.selectedOtherCommitteeID)
        }
      }
    },
    toggleRecipients() {
      if (this.toggleRecipients == false) {
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggleRecipients = false))
        }
        this.loadMessageAndReplyThreads()
      }
    },
    toggleBoard() {
      if (this.toggleBoard == false) {
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggleBoard = false))
        }

        if (this.isDebug === true)
          console.debug('watched toggle board selected=' + this.selectedBoardCommitteeID)

        if (
          this.selectedBoardCommitteeID &&
          this.selectedBoardCommitteeID != undefined &&
          this.selectedBoardCommitteeID > 0
        ) {
          this.addRecipient('Board of Directors')
          console.debug('toggleBoard recip modal=' + this.toggleRecipients)
        }
      }
    },
    toggleVendor() {
      if (this.toggleVendor == false) {
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggleVendor = false))
        }

        if (this.isDebug === true)
          console.debug('watched toggle vendors selected=' + this.selectedVendorIDs)

        if (
          this.selectedVendorIDs &&
          this.selectedVendorIDs != undefined &&
          this.selectedVendorIDs.length > 0
        ) {
          this.addRecipient('Vendors')
        }
      }
    },
    toggleHomeowner() {
      if (this.toggleHomeowner == false) {
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggleHomeowner = false))
        }

        if (this.isDebug === true)
          console.debug(
            'watched toggle homeowners selected=' + JSON.stringify(this.selectedOwnerIDs)
          )

        if (
          this.selectedOwnerIDs &&
          this.selectedOwnerIDs != undefined &&
          this.selectedOwnerIDs.length > 0
        ) {
          this.addRecipient('Homeowners')
        }
      }
    },
    toggleManagement() {
      if (this.toggleManagement == false) {
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggleManagement = false))
        }

        if (this.isDebug === true)
          console.debug(
            'watched toggle account managers selected=' +
              JSON.stringify(this.selectedAccountManagerIDS)
          )

        if (
          this.selectedAccountManagerIDS &&
          this.selectedAccountManagerIDS != undefined &&
          this.selectedAccountManagerIDS.length > 0
        ) {
          this.addRecipient('Management Portal Users')
        }
      }
    }
  },

  mounted() {
    this.reload()
  },

  methods,

  i18n: {
    messages: {
      en: { details: { title: 'Conversation Details' } }
    }
  }
}
</script>

<style lang="scss" scoped>
.owner-avatar {
  margin: 1em auto 2em;
}

.card {
  box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.05) !important;

  .card {
    border: 0 !important;
  }
}

.avatar-img {
  border: 1px solid #e7e8f2;
  padding: 0.25em;
}

.address-card {
  h5,
  h6 {
    margin: 0;
  }
}

.details-img {
  img {
    border-radius: 0.5em;
  }
}
</style>

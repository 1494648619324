import { conversationDetail } from '@/services/Conversations/ConversationDetails/store'
import { conversationAttachmentStore } from '@/services/Conversations/ConversationAttachment/store'
import { notifyError, notifyMessage, notifyProblem } from '@/services/notify'
import $ from 'jquery'
import _get from 'lodash/get'

export const methods = {
  async reload() {
    this.loading = true

    if (this.privateThreadMessageParent && this.privateThreadMessageParent != undefined) {
      this.loading = false
    }

    this.loading = false
  },

  open() {
    this.formData = {
      replyMessage: ''
    }
    this.isOpen = true
    this.loading = false
  },

  async submitReplyMessage() {
    if (!this.replyMessage || !this.replyMessage === '') {
      notifyError('The messge reply is required.')
      return
    }

    if (this.replyMessage.length >= 8000) {
      notifyError('The messge reply should not exceed 8,000 characters')
      return
    }

    await this.addReplyMessage()
  },

  async addReplyMessage() {
    this.loading = true

    await conversationDetail.dispatch('addMessageReply', {
      replyToMessageID: _get(this.privateThreadMessageParent, 'privateThreadMessageID', 0),
      privateThreadID: _get(this.privateThreadMessageParent, 'privateThreadID', 0),
      messageText: this.replyMessage,
      done: ({ details }) => {
        if (details && details != undefined) {
          if (
            details &&
            details.privateThreadMessageID &&
            details.privateThreadMessageID != undefined
          ) {
            this.saveAttachments(details.privateThreadMessageID)
            notifyMessage('Your reply has been added.')
            this.closeModal()
          } else {
            notifyError('There was a problem saving your attachments.')
          }
        }
        this.loading = false
      }
    })

    this.loading = false
  },

  async deleteDropFile(index) {
    this.files.splice(index, 1)

    const attachments = this.files

    const previews = this.previews
    this.previews.splice(index, 1)

    this.$store.dispatch('conversation/modify', {
      previews,
      attachments
    })
  },

  async updateAttachments() {
    const attachments = this.files
    if (!Array.isArray(attachments)) {
      notifyProblem('A problem occurred and the attachments could not be uploaded.')
      return
    }

    ////////////////////////////////
    //  Store previews
    ////////////////////////////////

    this.previews = this.files.map(file => {
      return URL.createObjectURL(file)
    })

    this.$store.dispatch('conversation/modify', {
      previews: this.previews,
      attachments
    })
  },

  async saveAttachments(id) {
    //Process one or more attachments
    try {
      if (this.isDebug === true) console.debug('files=' + this.files)

      if (this.files && this.files.length > 0) {
        for (let a = 0; a < this.files.length; a++) {
          await conversationAttachmentStore
            .dispatch('addConversationAttachment', {
              privateThreadMessageID: id
            })
            .then(async ({ returned }) => {
              if (
                returned &&
                returned != undefined &&
                returned.privateThreadMessageAttachmentID > 0
              ) {
                const payload = {
                  privateThreadMessageAttachmentID: returned.privateThreadMessageAttachmentID,
                  file: this.files[a]
                }

                await conversationAttachmentStore
                  .dispatch('uploadConversationAttachment', {
                    payload: payload
                  })
                  .then(() => {
                    this.base64pdf = ''
                  })
              }
            })
        }
      } else {
        notifyMessage('Successfully created the new conversation message.')
      }
    } catch (e) {
      notifyError(e)
    }
  },

  closeModal() {
    // auto-close modal
    this.$emit('update:toggle', (this.toggle = false))
    $('#clickclose').click()
    this.loading = false
  }
}

<template>
  <div>
    <PDF style="display: none;" :src="base64pdf" @num-pages="pageCount = new Array($event)" />
    <!-- Actual document -->
    <PDF
      v-for="(page, index) in pageCount"
      :key="index"
      :src="src"
      :page="index + 1"
      :scale="1"
      :resize="false"
    />
  </div>
</template>

<script>
/*
	import { PDFTile } from '@/components/tiles/PDF'

	<PDFTile src="" />
*/

import PDF from 'vue-pdf'

export default {
  components: {
    PDF
  },
  props: {
    src: String,
    pageCount: []
  }
}
</script>

export const methods = {
  open({ src }) {
    this.src = src
    this.isOpen = true
  },

  setTitle({ pdfTitle }) {
    this.pdfTitle = pdfTitle
  },

  zoom(direction) {
    const amt = Math.sqrt(2)
    if (direction > 0) {
      this.scale *= amt
    } else {
      this.scale /= amt
    }

    // constrain to min/max
    this.scale = Math.max(0.5, Math.min(4, this.scale))
  }
}

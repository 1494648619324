<template>
  <Modal :toggle.sync="isOpen" classes="pdf-modal">
    <div class="title" v-if="pdfTitle">{{ pdfTitle }}</div>
    <div>
      <pdf style="display: none;" :src="src" @num-pages="pageCount = new Array($event)" />
      <!-- Actual document -->
      <pdf
        v-for="(page, index) in pageCount"
        :key="index"
        :src="src"
        :page="index + 1"
        :scale="scale"
        :resize="true"
        :style="{ margin: 'auto', width: `${100 * scale}%` }"
      />
    </div>

    <div class="doc-controls has-text-center">
      <b-field position="is-centered">
        <div class="control">
          <b-button @click="zoom(-1)">-</b-button>
        </div>
        <div class="control">
          <b-button @click="zoom(1)">+</b-button>
        </div>
      </b-field>
    </div>
  </Modal>
</template>

<script>
/*
  Similar:
    @/components/PDF/Display
*/

/*
	import PDFModal from '@/components/Modals/PDF'

  <PDFModal ref="pdfModal" />

  pdfModal.open ({
    src: ""
  })
*/

import { dragscroll } from 'vue-dragscroll'
import pdf from 'vue-pdf'

import { methods } from './keys/methods'
import Modal from '@/components/Modal'

export default {
  components: {
    Modal,
    pdf
  },

  data() {
    return {
      isOpen: false,
      src: null,
      scale: 1,
      pdfTitle: '',
      pageCount: []
    }
  },

  methods,

  directives: { dragscroll }
}
</script>

<style lang="scss">
.pdf-modal {
  .doc-viewer {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 130%;
    min-height: 40vh;
    max-height: calc(100vh - 320px);
    border: 1px solid #dbdbdb;

    &.zoom-active {
      cursor: grab;
    }
  }
}
</style>
